<template>
  <div>
    <div class="vp">
      <audio id="customer">
        <source id="customer_source_id" src="../../assets/sound/customer.mp3" type="audio/mpeg">
      </audio>
      <audio id="dangerbet">
        <source id="dangerbet_source_id" src="../../assets/sound/danger_bet.mp3" type="audio/mpeg">
      </audio>
      <audio id="recharge">
        <source id="recharge_source_id" src="../../assets/sound/money_in.mp3" type="audio/mpeg">
      </audio>
      <audio id="exchange">
        <source id="exchange_source_id" src="../../assets/sound/money_out.mp3" type="audio/mpeg">
      </audio>
      <audio id="unauthorizeduser">
        <source id="unauthorizeduser_source_id" src="../../assets/sound/unauthorizeduser.mp3" type="audio/mpeg">
      </audio>
      <audio id="sbet">
        <source id="sbet_source_id" src="../../assets/sound/sbet.mp3" type="audio/mpeg">
      </audio>
      <audio id="phonecode">
        <source id="phonecode_source_id" src="../../assets/sound/phone_code.mp3" type="audio/mpeg">
      </audio>
    </div>

    <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#000000"
        text-color="#ffffff"
        active-text-color="#ffd04b"
        router
        :collapse-transition="false">

      <el-menu-item index="/administrator/main_statistic" v-if="!isrechargedomain"
                    :route="{path:'/administrator/main_statistic_simple',query:{'t':new Date().getTime()}}">
        <img src="../../assets/images/logo/ptn/logo01.png" style="height: 30px">
      </el-menu-item>
      <el-menu-item index="/administrator/manager_recharge"
                    :route="{path:'/administrator/manager_recharge',query:{'t':new Date().getTime()}}">
        입금
        <div style="" class="badge badge-danger tick countAni" v-if="rechargeCount > 0">{{ rechargeCount }}</div>
        <div style="" class="badge badge-primary tick2 countAni" v-if="ingrecharge > 0">{{ ingrecharge }}</div>
      </el-menu-item>
      <el-menu-item index="/administrator/manager_exchange"
                    :route="{path:'/administrator/manager_exchange',query:{'t':new Date().getTime()}}">
        환전
        <div style="" class="badge badge-danger tick countAni" v-if="exchangeCount > 0">{{ exchangeCount }}</div>
        <div style="" class="badge badge-primary tick2 countAni" v-if="ingexchange > 0">{{ ingexchange }}</div>
      </el-menu-item>
      <el-submenu index="100" show-timeout="50" hide-timeout="260" v-if="!isrechargedomain">
        <template slot="title">스포츠</template>
        <el-menu-item disabled>일반 스포츠</el-menu-item>
        <el-menu-item index="/administrator/manager_sports_1"
                      :route="{path:'/administrator/manager_sports_1',query:{'status':managerConst.Game.STATUS_CHECKING,'selectedStatus':'wait','selectedIsinplay':'sports','t':new Date().getTime()}}">
          일반 대기중
        </el-menu-item>
        <el-menu-item index="/administrator/manager_sports_2"
                      :route="{path:'/administrator/manager_sports_2',query:{'status':managerConst.Game.STATUS_OPEN,'selectedStatus':'open','selectedIsinplay':'sports','t':new Date().getTime()}}">
          일반 베팅가능
        </el-menu-item>
        <el-menu-item index="/administrator/manager_sports_3"
                      :route="{path:'/administrator/manager_sports_3',query:{'status':managerConst.Game.STATUS_STARTING,'selectedStatus':'start','selectedIsinplay':'sports','t':new Date().getTime()}}">
          일반 마감
        </el-menu-item>
        <el-menu-item index="/administrator/manager_sports_4"
                      :route="{path:'/administrator/manager_sports_4',query:{'status':managerConst.Game.STATUS_END,'selectedStatus':'end','selectedIsinplay':'sports','t':new Date().getTime()}}">
          일반 종료
        </el-menu-item>
        <el-menu-item disabled>In-play 스포츠</el-menu-item>
        <el-menu-item index="/administrator/manager_sports_5"
                      :route="{path:'/administrator/manager_sports_5',query:{'status':managerConst.Game.STATUS_CHECKING,'selectedStatus':'wait','selectedIsinplay':'inplay','t':new Date().getTime()}}">
          Inplay 대기중
        </el-menu-item>
        <el-menu-item index="/administrator/manager_sports_6"
                      :route="{path:'/administrator/manager_sports_6',query:{'status':managerConst.Game.STATUS_OPEN,'selectedStatus':'open','selectedIsinplay':'inplay','t':new Date().getTime()}}">
          Inplay 베팅가능
        </el-menu-item>
        <el-menu-item index="/administrator/manager_sports_7"
                      :route="{path:'/administrator/manager_sports_7',query:{'status':managerConst.Game.STATUS_STARTING,'selectedStatus':'start','selectedIsinplay':'inplay','t':new Date().getTime()}}">
          Inplay 마감
        </el-menu-item>
        <el-menu-item index="/administrator/manager_sports_8"
                      :route="{path:'/administrator/manager_sports_8',query:{'status':managerConst.Game.STATUS_END,'selectedStatus':'end','selectedIsinplay':'inplay','t':new Date().getTime()}}">
          Inplay 종료
        </el-menu-item>
      </el-submenu>
      <el-submenu index="200" show-timeout="50" hide-timeout="260" v-if="!isrechargedomain">
        <template slot="title">미니게임</template>
        <el-menu-item disabled>설정</el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_config"
                      :route="{path:'/administrator/manager_leisure_config',query:{'t':new Date().getTime()}}">
          종류,속성 설정
        </el-menu-item>
        <el-menu-item disabled>게임종류</el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game4"
                      :route="{path:'/administrator/manager_leisure_game4',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,'t':new Date().getTime()}}">
          Bet365 - 가상축구
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game5"
                      :route="{path:'/administrator/manager_leisure_game5',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER,'t':new Date().getTime()}}">
          Bet365 - 가상농구
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game6"
                      :route="{path:'/administrator/manager_leisure_game6',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK,'t':new Date().getTime()}}">
          Bet365 - 가상개경주
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game7"
                      :route="{path:'/administrator/manager_leisure_game7',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2,'t':new Date().getTime()}}">
          Bet365 - 가상경마
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game2"
                      :route="{path:'/administrator/manager_leisure_game2',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M,'t':new Date().getTime()}}">
          엔트리(파워볼,사다리,키노)
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game15"
                      :route="{path:'/administrator/manager_leisure_game15',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_3S,'t':new Date().getTime()}}">
          네임드(파워볼,사다리)
        </el-menu-item>

        <el-menu-item index="/administrator/manager_leisure_game3"
                      :route="{path:'/administrator/manager_leisure_game3',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1,'t':new Date().getTime()}}">
          보스코어(별다리)
        </el-menu-item>

        <el-menu-item index="/administrator/manager_leisure_game17"
                      :route="{path:'/administrator/manager_leisure_game17',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_NEXT_SADALI,'t':new Date().getTime()}}">
          Next게임(사다리,바카라)
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game18"
                      :route="{path:'/administrator/manager_leisure_game18',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_1M,'t':new Date().getTime()}}">
          슈어맨(파워볼,사다리)
        </el-menu-item>

        <el-menu-item index="/administrator/manager_leisure_game11"
                      :route="{path:'/administrator/manager_leisure_game11',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1,'t':new Date().getTime()}}">
          로투스(바카라,홀짝)
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisure_game16"
                      :route="{path:'/administrator/manager_leisure_game16',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERBALLRAMDOM,'t':new Date().getTime()}}">
          동행복권(파워볼,파워사다리)
        </el-menu-item>
        <!--                <el-menu-item index="/administrator/manager_leisure_game16"
                                      :route="{path:'/administrator/manager_leisure_game16',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_POWERBALL,'t':new Date().getTime()}}">
                            토큰파워볼
                        </el-menu-item>-->

        <!--                <el-menu-item index="/administrator/manager_leisure_game10"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game10',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA,'t':new Date().getTime()}}">-->
        <!--                    스카이 파크(바카라,홀짝)-->
        <!--                </el-menu-item>-->

        <!--                <el-menu-item index="/administrator/manager_leisure_game12"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game12',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA,'t':new Date().getTime()}}">-->
        <!--                    MGM(바카라,홀짝)-->
        <!--                </el-menu-item>-->

        <!--                                <el-menu-item index="/administrator/manager_leisure_game9"-->
        <!--                                              :route="{path:'/administrator/manager_leisure_game9',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_SUTDA,'t':new Date().getTime()}}">-->
        <!--                                    크라운(섯다)-->
        <!--                                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game12"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game12',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_JWCASINO_ANGELDEMONS,'t':new Date().getTime()}}">-->
        <!--                    JW Casino-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game13"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game13',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M,'t':new Date().getTime()}}">-->
        <!--                    GM 별다리-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game14"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game14',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M,'t':new Date().getTime()}}">-->
        <!--                    GM 보글보글 사다리-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game15"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game15',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_ACE,'t':new Date().getTime()}}">-->
        <!--                    GM 에이스 홀짝/오언-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game0"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game0',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_POWERBALL,'t':new Date().getTime()}}">-->
        <!--                    Tgame파워볼-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game1"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game1',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M,'t':new Date().getTime()}}">-->
        <!--                    하운슬로 파워볼-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_leisure_game19"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game19',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_PAMGAME_MARIO,'t':new Date().getTime()}}">-->
        <!--                    Pam게임-->
        <!--                </el-menu-item>-->

        <!--                <el-menu-item index="/administrator/manager_leisure_game16"-->
        <!--                              :route="{path:'/administrator/manager_leisure_game16',query:{'kind':managerConst.LeisureGame.LEISURE_KIND_SPEEDGAME_PDALI,'t':new Date().getTime()}}">-->
        <!--                    스피드게임(사다리)-->
        <!--                </el-menu-item>-->

      </el-submenu>
      <el-submenu index="300" show-timeout="50" hide-timeout="260" v-if="!isrechargedomain">
        <template slot="title">
          베팅내역
          <div style="" class="badge badge-danger tick countAni" v-if="sbetCount > 0">{{ sbetCount }}</div>
          <div style="" class="badge badge-danger tick countAni" v-if="dangerbetCount > 0">
            {{ dangerbetCount }}
          </div>
        </template>
        <el-menu-item index="/administrator/manager_sportsbet"
                      :route="{path:'/administrator/manager_sportsbet',query:{'t':new Date().getTime()}}">스포츠
          베팅내역
          <div style="" class="badge badge-danger tick countAni" v-if="sriskbetCount > 0">{{ sriskbetCount }}
          </div>
          <div style="" class="badge badge-danger tick countAni" v-if="sdangerbetCount > 0">
            {{ sdangerbetCount }}
          </div>
        </el-menu-item>
        <el-menu-item index="/administrator/manager_leisuregamebet"
                      :route="{path:'/administrator/manager_leisuregamebet',query:{'t':new Date().getTime()}}">
          미니게임 베팅내역
          <div style="" class="badge badge-danger tick countAni" v-if="lriskbetCount > 0">{{ lriskbetCount }}
          </div>
          <div style="" class="badge badge-danger tick countAni" v-if="ldangerbetCount > 0">
            {{ ldangerbetCount }}
          </div>
        </el-menu-item>
        <el-menu-item index="/administrator/manager_casino_honor"
                      :route="{path:'/administrator/manager_casino_honor',query:{'t':new Date().getTime()}}">
          카지노 베팅내역
        </el-menu-item>
        <el-menu-item index="/administrator/manager_tgame365_bet"
                      :route="{path:'/administrator/manager_tgame365_bet',query:{'t':new Date().getTime()}}">
          토큰게임 베팅내역
        </el-menu-item>
        <!--                <el-menu-item index="/administrator/manager_casino_reach"-->
        <!--                              :route="{path:'/administrator/manager_casino_reach',query:{'t':new Date().getTime()}}">Reach카지노 베팅내역-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_casino_ximax"-->
        <!--                              :route="{path:'/administrator/manager_casino_ximax',query:{'t':new Date().getTime()}}">-->
        <!--                    Ximax카지노 베팅내역-->
        <!--                </el-menu-item>-->


      </el-submenu>
      <el-submenu index="400" show-timeout="50" hide-timeout="260" v-if="!isrechargedomain">
        <template slot="title">
          회원관리
          <div style="" class="badge badge-danger tick countAni" v-if="unauthorizeduserCount > 0">
            {{ unauthorizeduserCount }}
          </div>
          <div style="" class="badge badge-primary tick2 countAni" v-if="ingnewuser > 0">{{ ingnewuser }}</div>
        </template>
        <el-menu-item index="/administrator/manager_user"
                      :route="{path:'/administrator/manager_user',query:{'t':new Date().getTime()}}">
          회원정보
        </el-menu-item>
        <el-menu-item index="/administrator/manager_rank"
                      :route="{path:'/administrator/manager_rank',query:{'t':new Date().getTime()}}">
          레벨관리
        </el-menu-item>
        <el-menu-item index="/administrator/manager_usergoup"
                      :route="{path:'/administrator/manager_usergoup',query:{'status':managerConst.User.STATUS_ENABLE,'t':new Date().getTime()}}">
          그룹관리
        </el-menu-item>
        <!--                <el-menu-item index="/administrator/manager_user2"-->
        <!--                              :route="{path:'/administrator/manager_user2',query:{'status':managerConst.User.STATUS_UNAUTHORIZED,'t':new Date().getTime()}}">-->
        <!--                    미인증 회원-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_user3"-->
        <!--                              :route="{path:'/administrator/manager_user3',query:{'status':managerConst.User.STATUS_AUTHORIZING,'t':new Date().getTime()}}">-->
        <!--                    인증중회원-->
        <!--                </el-menu-item>-->

      </el-submenu>
      <el-submenu index="600" show-timeout="50" hide-timeout="260"  v-if="!isrechargedomain">
        <template slot="title">
          이벤트,공지
          <div style="" class="badge badge-danger tick" v-if="boardCount > 0">{{ boardCount }}</div>
        </template>
        <el-menu-item index="/administrator/manager_board1"
                      :route="{path:'/administrator/manager_board1',query:{'t':new Date().getTime(),type:managerConst.Board.TYPE_EVENT,noticeType:null}}">
          이벤트
        </el-menu-item>
        <el-menu-item index="/administrator/manager_board2"
                      :route="{path:'/administrator/manager_board2',query:{'t':new Date().getTime(),type:managerConst.Board.TYPE_NOTICE,noticeType:managerConst.Board.NOTICE_TYPE_IN_FREEBOARD}}">
          공지사항
        </el-menu-item>
        <el-menu-item index="/administrator/manager_board3"
                      :route="{path:'/administrator/manager_board3',query:{'t':new Date().getTime(),type:managerConst.Board.TYPE_NOTICE,noticeType:managerConst.Board.NOTICE_TYPE_LINE}}">
          한줄공지
        </el-menu-item>
        <el-menu-item index="/administrator/manager_board4"
                      :route="{path:'/administrator/manager_board4',query:{'t':new Date().getTime(),type:managerConst.Board.TYPE_FREE_BOARD}}">
          게시글관리
          <div style="" class="badge badge-danger tick" v-if="boardCount > 0">{{ boardCount }}</div>
        </el-menu-item>
        <!--                <el-menu-item index="/administrator/manager_board4"-->
        <!--                              :route="{path:'/administrator/manager_board4',query:{'t':new Date().getTime(),type:managerConst.Board.TYPE_NOTICE,noticeType:managerConst.Board.NOTICE_TYPE_IN_BETFORM}}">-->
        <!--                    베팅규칙-->
        <!--                </el-menu-item>-->
        <!--                <el-menu-item index="/administrator/manager_board5"-->
        <!--                              :route="{path:'/administrator/manager_board5',query:{'t':new Date().getTime(),type:managerConst.Board.TYPE_FREE_BOARD,noticeType:null}}">-->
        <!--                    게시판-->
        <!--                </el-menu-item>-->
      </el-submenu>
      <el-submenu index="800" show-timeout="50" hide-timeout="260"  v-if="!isrechargedomain">
        <template slot="title">
          종목,리그
        </template>
        <el-menu-item index="/administrator/manager_gametype"
                      :route="{path:'/administrator/manager_gametype',query:{'t':new Date().getTime()}}">
          종목관리
        </el-menu-item>
        <el-menu-item index="/administrator/manager_league"
                      :route="{path:'/administrator/manager_league',query:{'t':new Date().getTime()}}">
          리그관리
        </el-menu-item>
        <el-menu-item index="/administrator/manager_league_matching"
                      :route="{path:'/administrator/manager_league_matching',query:{'t':new Date().getTime()}}">
          리그매칭관리
        </el-menu-item>
        <el-menu-item index="/administrator/manager_teamnamemaching"
                      :route="{path:'/administrator/manager_teamnamemaching',query:{'t':new Date().getTime()}}">
          팀명매칭
        </el-menu-item>


      </el-submenu>
      <el-menu-item index="/administrator/manager_customer" v-if="!isrechargedomain"
                    :route="{path:'/administrator/manager_customer',query:{'t':new Date().getTime()}}"
                    >
        고객센터
        <div style="" class="badge badge-danger tick countAni" v-if="customerCount > 0">{{ customerCount }}</div>
        <div style="" class="badge badge-primary tick2 countAni" v-if="ingcustomer > 0">{{ ingcustomer }}</div>
      </el-menu-item>

      <el-submenu index="501" show-timeout="50" hide-timeout="260"  v-if="!isrechargedomain">
        <template slot="title">코드,총판</template>
        <el-menu-item index="/administrator/manager_agent"
                      :route="{path:'/administrator/manager_agent',query:{'t':new Date().getTime()}}">
          총판관리
        </el-menu-item>
        <el-menu-item index="/administrator/manager_register_code"
                      :route="{path:'/administrator/manager_register_code',query:{'t':new Date().getTime()}}">
          코드관리
        </el-menu-item>
      </el-submenu>
      <el-submenu index="900" show-timeout="50" hide-timeout="260" v-if="!isrechargedomain">
        <template slot="title" >
          기타(로그,설정)
        </template>
        <el-menu-item index="/administrator/manager_message"
                      :route="{path:'/administrator/manager_message',query:{'t':new Date().getTime()}}">
          쪽지관리
        </el-menu-item>
        <el-menu-item index="/administrator/manager_siteconfig"
                      :route="{path:'/administrator/manager_siteconfig',query:{'t':new Date().getTime()}}">
          사이트설정
        </el-menu-item>
        <el-menu-item index="/administrator/manager_crossrule"
                      :route="{path:'/administrator/manager_crossrule',query:{'t':new Date().getTime()}}">
          크로스 룰 설정
        </el-menu-item>
        <el-menu-item index="/administrator/manager_custemp"
                      :route="{path:'/administrator/manager_custemp',query:{'t':new Date().getTime()}}">
          템플렛 설정
        </el-menu-item>
        <el-menu-item index="/administrator/manager_popup"
                      :route="{path:'/administrator/manager_popup',query:{'t':new Date().getTime()}}">
          팝업 설정
        </el-menu-item>
        <el-menu-item index="/administrator/manager_ipcontainer"
                      :route="{path:'/administrator/manager_ipcontainer',query:{'t':new Date().getTime()}}">
          IP 설정
        </el-menu-item>
        <el-menu-item index="/administrator/manager_changepasswd"
                      :route="{path:'/administrator/manager_changepasswd',query:{'t':new Date().getTime()}}">
          관리자 비번변경
        </el-menu-item>
        <el-menu-item disabled></el-menu-item>
        <el-menu-item index="/administrator/manager_cashpointlog1"
                      :route="{path:'/administrator/manager_cashpointlog1',query:{'t':new Date().getTime(),userOrPartner:managerConst.CashPointLog.LOG_CASHPOINT_USER}}">
          회원 머니&포인트로그
        </el-menu-item>
        <el-menu-item index="/administrator/manager_cashpointlog2"
                      :route="{path:'/administrator/manager_cashpointlog2',query:{'t':new Date().getTime(),userOrPartner:managerConst.CashPointLog.LOG_CASHPOINT_PARTNER}}">
          파트너 머니&포인트로그
        </el-menu-item>
        <el-menu-item index="/administrator/manager_modifiedhistory"
                      :route="{path:'/administrator/manager_modifiedhistory',query:{'t':new Date().getTime()}}">
          변경로그
          <div style="" class="badge badge-danger tick" v-if="modifiedCount > 0">{{ modifiedCount }}</div>
        </el-menu-item>
        <el-menu-item index="/administrator/manager_loglogin"
                      :route="{path:'/administrator/manager_loglogin',query:{'t':new Date().getTime()}}">
          로그인 로그
        </el-menu-item>
        <el-menu-item index="/administrator/manager_logloginfail"
                      :route="{path:'/administrator/manager_logloginfail',query:{'t':new Date().getTime()}}">
          로그인 <span class="text-red">실패</span> 로그
        </el-menu-item>
        <el-menu-item disabled></el-menu-item>
        <el-menu-item @click="signout">
          로그아웃
        </el-menu-item>
      </el-submenu>
      <el-menu-item @click="signout" v-if="isrechargedomain" style="margin-left: 50px">
        로그아웃
      </el-menu-item>


      <!--                        <el-menu-item index="/administrator/manager_phonecode"-->
      <!--                                      :route="{path:'/administrator/manager_phonecode',query:{'t':new Date().getTime()}}">-->
      <!--                            휴대폰인증코드-->
      <!--                            <div style="" class="badge badge-danger tick countAni" v-if="phonecodeCount > 0">{{phonecodeCount}}</div>-->
      <!--                        </el-menu-item>-->


    </el-menu>

    <div style="position: absolute;z-index: 9999;top: 10px;right: 10px;color: #fff9ff;font-size: 28px;cursor: pointer"
         @click="soundSet">
      <i class="fa fa-volume-up" v-if="$store.state.m.alarmOn == managerConst.YES"></i>
      <i class="fa fa-volume-off text-red" v-if="$store.state.m.alarmOn == managerConst.NOT"></i>
      <i class="fa fa-close  text-red" v-if="$store.state.m.alarmOn == managerConst.NOT"></i>
    </div>
  </div>


</template>

<script>


import {manager} from "../../common/administrator/managerMixin";
import {getInfomation, managerSignOut} from "../../network/manager/commonRequest";
import managerConst from "../../common/administrator/managerConst";
import localStorage from "../../store/localStorage";
import {findsiteCommonConfigOnly} from "../../network/manager/siteConfigRequest";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "@/store/mutation-types";
import {dobet} from "@/network/sportsBetRequest";

export default {
  name: "ManagerTopbarComp",
  components: {},
  mixins: [manager],
  data() {
    return {
      activeIndex: '1',
      infomation: {},
      rechargeCount: 0,
      exchangeCount: 0,
      customerCount: 0,
      phonecodeCount: 0,
      unauthorizeduserCount: 0,
      dangerbetCount: 0,
      sdangerbetCount: 0,
      ldangerbetCount: 0,
      sriskbetCount: 0,
      lriskbetCount: 0,
      sbetCount: 0,
      boardCount: 0,
      modifiedCount: 0,
      siteConfig: {},
      riskAlarmAble: true,
      ingrecharge: 0,
      ingexchange: 0,
      ingnewuser: 0,
      ingcustomer: 0,
      isrechargedomain:false,
    }
  },
  methods: {
    soundSet() {
      if (this.$store.state.m.alarmOn) {
        localStorage.set('alarm', managerConst.NOT)
        this.$store.state.m.alarmOn = managerConst.NOT
      } else {
        localStorage.set('alarm', managerConst.YES)
        this.$store.state.m.alarmOn = managerConst.YES
      }
    },
    getInfomation() {
      getInfomation().then(res => {
        this.infomation = res.data.data;
        this.rechargeCount = this.infomation.rechargeCount;
        this.exchangeCount = this.infomation.exchangeCount;
        this.ingrecharge = this.infomation.ingrecharge;
        this.ingexchange = this.infomation.ingexchange;
        if(!this.isrechargedomain){
          this.warningHighAmountSportsBet(this.infomation.sportsHighamountBetList)
          this.warningHighAmountLeisureBet(this.infomation.leisureHighamountBetList);
          this.boardCount = this.infomation.boardCount;
          this.customerCount = this.infomation.cusCount;
          this.phonecodeCount = this.infomation.codeCount;
          this.unauthorizeduserCount = this.infomation.unauthorizedUserCount;
          this.sdangerbetCount = this.infomation.sportsHighamountBetList.length
          this.ldangerbetCount = this.infomation.leisureHighamountBetList.length
          this.dangerbetCount = this.infomation.sportsHighamountBetList.length + this.infomation.leisureHighamountBetList.length;
          this.lriskbetCount = this.infomation.riskBetCount1
          this.sriskbetCount = this.infomation.riskBetCount2
          this.sbetCount = this.infomation.riskBetCount1 + this.infomation.riskBetCount2;
          this.modifiedCount = this.infomation.modifiedCount;
          this.ingnewuser = this.infomation.ingnewuser;
          this.ingcustomer = this.infomation.ingcustomer;
        }

        if (this.rechargeCount > 0) {
          this.playSound('recharge')
        }
        if (this.exchangeCount > 0) {
          this.playSound('exchange')
        }
        if(!this.isrechargedomain){
          if (this.customerCount > 0) {
            this.playSound('customer')
          }
          if (this.unauthorizeduserCount > 0) {
            this.playSound('unauthorizeduser')
          }
          if (this.dangerbetCount > 0 && this.riskAlarmAble == managerConst.YES) {
            this.playSound('dangerbet')
          }
          if (this.sbetCount > 0 && this.riskAlarmAble == managerConst.YES) {
            this.playSound('sbet')
          }
        }

      })
    },
    warningHighAmountSportsBet(betList) {
      betList.map(betInfo => {
        let title = betInfo.isinplay == managerConst.YES ? 'Inplay' : 'Sports'
        let content = '';
        content += betInfo.user.nickname + '<br/>'
        content += betInfo.betCount + '폴더'
        content += '<br/>예상적중:' + (betInfo.totalCash)
        this.$notify({
          title: title + ' 고액베팅 경고',
          message: content,
          dangerouslyUseHTMLString: true,
          type: 'warning',
          duration: 2000,
          position: 'bottom-left'
        });
      })
    },
    warningHighAmountLeisureBet(betList) {
      betList.map(betInfo => {

        let content = '';
        content += betInfo.kindConfig.kindName + '<br/>' + betInfo.user.nickname
        content += '<br/>예상적중:' + (betInfo.totalCash)
        this.$notify({
          title: '미니게임 고액베팅 경고',
          message: content,
          dangerouslyUseHTMLString: true,
          type: 'warning',
          duration: 2000,
          position: 'bottom-left'
        });
      })
    },

    playSound(type) {
      let mp3 = null;
      switch (type) {
        case 'customer':
          mp3 = document.getElementById("customer")
          break;
        case 'recharge':
          mp3 = document.getElementById("recharge")
          break;
        case 'exchange':
          mp3 = document.getElementById("exchange")
          break;
        case 'dangerbet':
          mp3 = document.getElementById("dangerbet")
          break;
        case 'unauthorizeduser':
          mp3 = document.getElementById("unauthorizeduser")
          break;
        case 'sbet':
          mp3 = document.getElementById("sbet")
          break;
        case 'phonecode':
          mp3 = document.getElementById("phone_code")
          break;
        default:
          mp3 = document.getElementById("customer")
      }
      if (this.$store.state.m.alarmOn == managerConst.YES) {
        let nt = this.$moment().unix();
        let t = localStorage.get('alarmTime', 0)
        if (nt - t > 5) {
          localStorage.set("alarmTime", this.$moment().unix());
          mp3.play()
        }
      }
    },
    signout() {

      this.$swal({
        title: '로그아웃 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          managerSignOut()
          this.$router.push({path: '/manager'})
        }
      })
    }

  },
  computed: {},
  created() {
    let domain = window.location.host;
    if(domain.indexOf('honee357') !== -1){
    //if(domain.indexOf('localhost') !== -1){
      this.isrechargedomain = true;
      console.log(domain)
    }
    this.getInfomation()
    setInterval(this.getInfomation, 5000);

    findsiteCommonConfigOnly().then(res => {
      this.riskAlarmAble = res.data.data.riskBetAlarmable;
    });
  },
  mounted() {

  },
  watch: {},
}
</script>

<style scoped>
.el-menu {
  border: 0 solid transparent !important;
  font-weight: bold;

}

.tick {
  position: absolute;
  border-radius: 5px;
  font-size: 10px;
  top: 5px;
  right: 2px
}

.tick2 {
  position: absolute;
  border-radius: 5px;
  font-size: 10px;
  bottom: 2px;
  left: 2px
}

.vp {
  position: absolute;
  top: -1000px;
}

.countAni {
  animation: 1s ease-in-out 0s infinite normal none running twinkling;
}


@keyframes twinkling {

  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

</style>